import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import ContactPage from '../ui/pages/ContactPage'
import { Formik } from 'formik'
import { schema, handleSubmit, initialValues } from '../integrations/contact'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const Page = props => (
  <>
    <HelmetComponent
      title={metaTags.contactTitle}
      description={metaTags.contact}
      page="/contact"
    />
    <AppWrapper {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit('Contact page')}
        validationSchema={schema}
      >
        {formikProps => <ContactPage formik={formikProps} />}
      </Formik>
    </AppWrapper>
  </>
)

export default Page
